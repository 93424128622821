

















import { Vue, Component } from 'vue-property-decorator';
import {i18n} from '@/main';
import {getStatic} from '@/utilities/static';

@Component({
  metaInfo() {
    return {title: i18n.tc('meta.error.title')};
  },
})
export default class ErrorMaintenance extends Vue {
  private ErrorIcon = getStatic('icons/errors/error-icon.svg');
  private Illustration = getStatic('icons/errors/505.svg');
}
